import React, { useEffect, useMemo, useState } from 'react';
import { StyleHubspot } from '../Mapping/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { StylesProgress } from './styles';
import { TableHubSpot } from '../../../../Components/Table/HubSpot/tableHubSpot';

import { Button } from '../../../../Components/Buttons';
import { useValidationTokenQuery, useGetGeneralConfigAppQuery, useGetAccountAppMutation } from '../../../../Store/api/apps';
import { useNavigate } from 'react-router-dom';
import { getObjectsQueryUrl } from '../../../../Utils/QueryUrl';
import { useGetCountTransactionsByClientMutation } from '../../../../Store/api/count';


export const Transactions = () => {
  let objQuery = getObjectsQueryUrl(window.location.href);
  const [percentage, setPercentage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [transactionsData, setTransactionsData] = useState({
    total: 0,
    current: 0,
    cutDay: 0,
  });
  const [data, setData] = useState([]);
  let { data: dataValidation, error: errorValidation, isLoading } = useValidationTokenQuery();
  let { isLoading: isLoadingGeneral, isSuccess: isSuccessGeneral, data: responseGeneral } = useGetGeneralConfigAppQuery(objQuery.appId);
  let [searchAccount, { isLoading: isLoadingAccount, isSuccess: isSuccessAccount, data: responseAccount }] = useGetAccountAppMutation();
  let [searchTransactions, { isLoading: isLoadingTransactions, isSuccess: isSuccessTransaction, data: responseTransactions }] = useGetCountTransactionsByClientMutation();
  let navigate = useNavigate();


  useEffect(() => {
    //consulta para identificar el ID del Cliente del integrador que esta en otra base de datos
    if(isSuccessGeneral){
      searchAccount({
        accountId: objQuery.accountId,
        portalId: objQuery.portalId,
        appId: objQuery.appId,
        nameApp: responseGeneral.data.config.app_name
      })
    }
  }, [isSuccessGeneral])

  useEffect(() => {
    if(isSuccessAccount) {
      transactionRequest()
    }
  }, [isSuccessAccount])
  
  useEffect(() => {
    if(isSuccessTransaction) {
      let dataControlPlan = responseAccount.data.data.ControlPlan;
      let dataCountTransactions = responseTransactions.data;
      let current = dataCountTransactions.find(el => el.category === "all")?.count || 0;

      /*  */
      setTransactionsData({
        total: dataControlPlan.limitPlan,
        current: current,
        cutDay: dataControlPlan.cutoffDate,
      })
      setPercentage(Math.floor((current / dataControlPlan.limitPlan) * 100));
      setData(dataCountTransactions);
    }
  }, [isSuccessTransaction])
  

  useEffect(() => {
    if(errorValidation) {
      if(errorValidation.status === 403) {
        navigate('/');
      }
    }
  }, [errorValidation])

  const columns = useMemo(
    () => [

      {
        accessorFn: row => row.category,
        id: 'category',
        header: () => <span>Category</span>,
        cell: info => info.getValue().replaceAll("_", " ").toUpperCase(),
      },
      {
        id: 'count',
        accessorFn: row => Number(row.count),
        header: () => <span>Quantity</span>,
        cell: info => info.getValue().toLocaleString("en"),
        meta: {
          filterVariant: 'range',
        },
      },

    ],
    []
  )
  
  const cutOffDay = (dataControlPlan) => {
     // -----  Fechas de Corte  -------
    const fechaGMT = new Date();

    // Convertir el desplazamiento de minutos a milisegundos
    const desplazamientoMilisegundos = 5 * 60 * 60 * 1000;

    // Aplicar el desplazamiento para obtener la fecha en la zona horaria local Colombia
    const fechaLocal = new Date(fechaGMT.getTime() - desplazamientoMilisegundos);

    // Obtener el mes y el año actual
    const mesActual = fechaLocal.getMonth() + 1; // Los meses comienzan desde 0
    const añoActual = fechaLocal.getFullYear();

    // Día dado (ejemplo: el día 7)

    const diaCorte = Number(dataControlPlan.cutoffDate);

    // Si quieres establecer un día específico, puedes cambiar el valor de diaDado aquí
    let diaHoy = fechaLocal.getDate()
    let restar = diaCorte > diaHoy ? 1 : 0;

    // Calcular la fecha anterior
    const fechaAnterior = new Date(añoActual, mesActual - 1 - restar, diaCorte); // Restamos 2 al mes para obtener el mes anterior

    // Calcular la fecha siguiente
    const fechaSiguiente = new Date(añoActual, mesActual - restar, diaCorte); // Sumamos 1 al mes para obtener el siguiente mes

    // Formatear las fechas como "YYYY-MM-DD"

    const fechaAnteriorFormateada = new Date(fechaAnterior.getTime() - desplazamientoMilisegundos).toISOString();
    const fechaSiguienteFormateada = new Date(fechaSiguiente.getTime() - desplazamientoMilisegundos).toISOString();

    return {
      start: fechaAnteriorFormateada,
      end: fechaSiguienteFormateada,
      start1: fechaAnterior,
      end1: fechaSiguiente
    }
  }
  const transactionRequest = () => {
    let idClientIntegrador = responseAccount.data.data.AppsSetting?.idClientIntegrador ||  responseAccount.data.data.VtexSetting?.id_client_integrador ||  responseAccount.data.data.ShopifySetting?.id_client_integrador
    let categoriesApp = responseGeneral.data.config.transactions;
    let dataControlPlan = responseAccount.data.data.ControlPlan;
    let cutOffDayObj = cutOffDay(dataControlPlan);
    searchTransactions(
      {idClient: idClientIntegrador, dateStart: cutOffDayObj.start, dateEnd: cutOffDayObj.end,  categories: categoriesApp},
    )
  }
  const handleSyncNow = () => {
    transactionRequest()
  }
  return (
    <StyleHubspot>
      <section className='section-general'>
        <Tabs>
          <TabList>
            <Tab className={"tab-item"}>Transactions View</Tab>
          </TabList>
          <TabPanel>
            <h2>Transacciones</h2>
            <StylesProgress>
              <div className='circle-transactions'>
                <SemiCircleProgressBar percentage={percentage } stroke={percentage < 60 ? "var(--primary-hubspot-color)": percentage < 90 ? "#f4d739": "#e33737"} strokeWidth={15} diameter={250} showPercentValue />
              </div>
              <div className='info-transactions'>
                <h2> {transactionsData.current.toLocaleString("en")} / {transactionsData.total.toLocaleString("en")} </h2>
                <div>
                  <p>
                    <b>Cut-off date:</b> {transactionsData.cutDay}th of each month
                  </p>
                </div>
              </div>
            </StylesProgress>
            <section style={{maxWidth: "600px"}}>
              <div className='section-btn'>
                <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
                <Button className={"hubspot button--sm"} onPress={handleSyncNow}>Refresh</Button>
              </div>
              <div >
                <TableHubSpot dataTable={data} showFilter={showFilter} columns={columns} rowExpand={false} />
              </div>
            </section>
          </TabPanel>
        </Tabs>
      </section>
    </StyleHubspot>
  );
};
